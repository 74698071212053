import React, { lazy, Suspense } from 'react';
import Layout from '../../components/layout';
import styles from '../../styles/Home.module.css';

// Importando o JSON de loterias
import { loterries } from '../../utils/lotteries';
import { LoteriaType } from '../../utils/interfaces';

const ConstestIcon = lazy(() => import('../../components/ConstestIcon'));
const LoteriasResume = React.lazy(() => import('../../components/Seo/home'));

const Page = () => {
  const title = 'Resultados das Loterias - Lotofácil, Mega-Sena, Quina!';
  const description = 'Confira os resultados atualizados de todos os sorteios da Lotofácil, Mega-Sena e Quina. Acesse os números sorteados, prêmios, estatísticas e dicas para aumentar suas chances de ganhar.';
  const keywords = 'resultados loterias, lotofácil resultados, mega-sena resultados, quina resultados, sorteios loterias, como jogar loterias, estatísticas loterias, prêmios loterias, sorteios ao vivo, ganhar loteria, loterias caixa';

  return (
    <Layout title={title} description={description} keywords={keywords}>
      <div style={{ padding: '1rem' }}>

        <h1 className="subtitle">Resultado de Todos os Concursos das Loterias:</h1>
        <p>Informações de todos os sorteios/concursos da Lotofácil, Mega Sena, Quina de hoje, ontem e do mês inteiro (Segunda, Terça, Quarta, Quinta, Sexta e Sábado)</p>

        <section className={styles.contestsContainer}>
          <h2>Últimos resultados das Lóterias</h2>
          <div>
            {/* Exibindo apenas Lotofácil, Mega-Sena e Quina */}
            {['lotofacil', 'megasena', 'quina', 'duplasena', 'diadesorte', 'supersete', 'lotomania'].map((lotteryKey: string) => {
              const lottery = lotteryKey && loterries[lotteryKey] ? loterries[lotteryKey] : {
                title: "Loteria não encontrada",
                description: "Informações não disponíveis.",
                keywords: "loteria não encontrada",
                paths: [{ href: "/", label: "Home" }],
                loteriaName: "Loteria desconhecida",
                contestUrl: "/",
                loteriaColor: "#000000"
              }; 

              return (
                <a
                  href={`/${lotteryKey}`} // Caminho baseado no nome da loteria
                  className={styles.contestsResultLink}
                  style={{
                    borderColor: lottery.loteriaColor,
                    textDecorationColor: lottery.loteriaColor,
                    color: lottery.loteriaColor,
                  }}
                  key={lotteryKey}
                >
                  <Suspense fallback='Imagem Carregando...'>
                    <ConstestIcon color={lottery.loteriaColor} />
                  </Suspense>
                  <p>
                    <strong>Resultados da {lottery.loteriaName}</strong>
                    <span>Confira os Números Sorteados e Ganhadores</span>
                  </p>
                </a>
              );
            })}
          </div>
        </section>
        
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <LoteriasResume />
        </Suspense>
      </div>
    </Layout>
  );
};

export default Page;
