import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ContactPage from './pages/Contact';
import AboutPage from './pages/AboutUs';
import LegalDisclaimer from './pages/LegalDisclaimer';

import Home from './pages/Home';
import LotteryHome from './pages/Home/LotteryHome';

import ContestPage from './pages/Contest';

import './styles/global.css';
import Transparency from './pages/Transparency';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:lotteryType" element={<LotteryHome />} />
          <Route path="/concurso/:numero" element={<ContestPage />} />
          <Route path="/:lotteryType/concurso/:numero" element={<ContestPage />} />
          <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
          <Route path="/termos-de-uso" element={<TermsOfUse />} />
          <Route path="/contato" element={<ContactPage />} />
          <Route path="/sobre-nos" element={<AboutPage />} />
          <Route path="/aviso-legal" element={<LegalDisclaimer />} />
          <Route path="/transparencia" element={<Transparency />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;