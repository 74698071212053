import React from 'react';
import styles from '../../styles/Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <p>&copy; {new Date().getFullYear()} Minha Sorte</p>
      <nav className={styles.nav}>
        <a href="/" className={styles.footerLink}>Home</a>
        <a href="/sobre-nos" className={styles.footerLink}>Sobre Nós</a>
        <a href="/politica-de-privacidade" className={styles.footerLink}>Política de Privacidade</a>
        <a href="/termos-de-uso" className={styles.footerLink}>Termos de Uso</a>
        <a href="/contato" className={styles.footerLink}>Contato</a>
        <a href="/aviso-legal" className={styles.footerLink}>Aviso Legal</a>
        <a href="/transparencia" className={styles.footerLink}>Transparência</a>
      </nav>
      <p className={styles.footerText}>
        Os resultados das loterias exibidos neste site são fornecidos unicamente para fins informativos.
        Embora façamos todos os esforços para garantir a precisão das informações apresentadas, não assumimos
        nenhuma responsabilidade por eventuais erros ou omissões. Os dados oficiais podem ser verificados nos sites
        das loterias correspondentes. Participar de jogos de azar pode ser arriscado; jogue com responsabilidade.
        <br /><br />
        Para maiores informações sobre os resultados das loterias, visite o site oficial da Caixa Econômica Federal.
      </p>
    </footer>
  );
};

export default Footer;
