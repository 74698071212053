import React, { useState, useEffect } from 'react';
import styles from '../../styles/PopupBanner.module.css'; // Crie um CSS específico para o pop-up

interface PromoBanner {
  imageDesktop: string;
  imageMobile: string;
  link: string;
  alt: string;
  discountCoupons: string[];
  title: string;
  startDate?: string;
  endDate?: string;
}

interface PopupProps {
  banners: PromoBanner[];
}

const getRandomBanner = (banners: PromoBanner[]) => {
  const validBanners = banners.filter(banner => {
    const today = new Date();
    const start = banner.startDate ? new Date(banner.startDate) : new Date(0);
    const end = banner.endDate ? new Date(banner.endDate) : new Date();
    return today >= start && today <= end;
  });

  return validBanners[Math.floor(Math.random() * validBanners.length)];
};

const PopupBanner: React.FC<PopupProps> = ({ banners }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [currentBanner, setCurrentBanner] = useState<PromoBanner | null>(null);

  useEffect(() => {
    // Verifica se o pop-up foi fechado anteriormente
    const popupClosed = sessionStorage.getItem('popupClosed');
    if (popupClosed === 'true') {
      setShowPopup(false); // Se o pop-up foi fechado, não exibe mais
      return;
    }

    const banner = getRandomBanner(banners);
    setCurrentBanner(banner);

    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 40000); // Pop-up desaparece após 40 segundos

    return () => clearTimeout(timer);
  }, [banners]);

  const handleClosePopup = () => {
    setShowPopup(false);
    sessionStorage.setItem('popupClosed', 'true'); // Salva no sessionStorage que o pop-up foi fechado
  };

  if (!currentBanner) return null;

  return (
    <div className={styles.popupWrapper} style={{ display: showPopup ? 'block' : 'none', overflow: 'auto' }}>
      <div className={styles.popupContent}>
        <button className={styles.closeButton} onClick={handleClosePopup}>
          X
        </button>
        <a style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column' }} href={currentBanner.link} target="_blank" rel="noopener noreferrer">
          <h2 className={styles.popupContentTitle}>{currentBanner.title}</h2>
          <img
            src={window.innerWidth < 768 ? currentBanner.imageMobile : currentBanner.imageDesktop}
            alt={currentBanner.alt}
            className={styles.bannerImage}
          />
          <div className={styles.bannerDetails}>
            {currentBanner.discountCoupons.length > 0 && (
              <div className={styles.coupons}>
                <h3>Cupons de Desconto:</h3>
                <ul>
                  {currentBanner.discountCoupons.map((coupon, index) => (
                    <li key={index}>{coupon}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

export default PopupBanner;
