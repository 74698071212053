import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './header.module.css';
import PrivacyControl from '../PrivacyControl';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.header}>
      <button 
        className={styles.menuToggle} 
        onClick={toggleMenu} 
        aria-label="Abrir menu" 
        aria-expanded={isMenuOpen ? "true" : "false"} 
        aria-controls="menu"
      >
        <span className={styles.menuIcon}></span>
        <span className={styles.menuIcon}></span>
        <span className={styles.menuIcon}></span>
      </button>
      <div className={styles.logoContainer}>
        <Link to="/" className={styles.headerLink}>
          Resultados Loteria
        </Link>
      </div>
      <nav id="menu" className={`${styles.nav} ${isMenuOpen ? styles.open : ''}`}>
        <ul className={styles.navList}>
          <li><Link to="/lotofacil" className={styles.navLink}>Lotofácil</Link></li>
          <li><Link to="/megasena" className={styles.navLink}>Mega Sena</Link></li>
          <li><Link to="/quina" className={styles.navLink}>Quina</Link></li>
          <li><Link to="/supersete" className={styles.navLink}>Super Sete</Link></li>
          <li><Link to="/diadesorte" className={styles.navLink}>Dia de Sorte</Link></li>
          <li><Link to="/lotomania" className={styles.navLink}>Lotomania</Link></li>
          <li><Link to="/duplasena" className={styles.navLink}>Dupla Sena</Link></li>
        </ul>
      </nav>
      <PrivacyControl />
    </header>
  );
};

export default Header;
