import OfertasAntecipadasBlackMobile from '../assets/ads/ofertas-antecipadas-black-amazon.png';
import PrimeOfertas from '../assets/ads/esquenta-ofertas-prime-black-friday.png';
import PrimeOfertasMobile from '../assets/ads/esquenta-ofertas-prime-black-friday-mobile.png';
import PrimeAssinaturaAnualOfertas from '../assets/ads/prime-assinatura-plano-anual.png';
import PrimeAssinaturaAnualOfertasMobile from '../assets/ads/prime-assinatura-plano-anual-mobile.png';
import Livros50OffOfertas from '../assets/ads/ofertas-livros-50-off.png';
import Livros50OffOfertasMobile from '../assets/ads/ofertas-livros-50-off-mobile.png';
import Casa30OffOfertas from '../assets/ads/ofertas-casa-30-off.png';
import Casa30OffOfertasMobile from '../assets/ads/ofertas-casa-30-off-mobile.png';
import Brinquedos25OffOfertas from '../assets/ads/ofertas-brinquedos-25-off.png';
import Brinquedos25OffOfertasMobile from '../assets/ads/ofertas-brinquedos-25-off-mobile.png';
import Oferta15OffNikeMeliuz from '../assets/ads/15-off-nike-meliuz.png';
import OfertasBlackShopee from '../assets/ads/ofertas-shopee-black-friday.png';
import OfertasBlackShopeeMobile from '../assets/ads/ofertas-shopee-black-friday-mobile.png';
import Banner99Pay200CDI from '../assets/ads/99-pay-200-cdi.jpg';
import Banner99Pay130Cupom from '../assets/ads/99-pay-130-cupom.jpg';

export const adsAmazon = (numero: string | undefined) => [
  {
    imageDesktop: 'https://m.media-amazon.com/images/G/32/br-events/2024/BF24/WU/TrafficChannels/Associates/WarmUp/17498_BFWU24_Associates_2148x588.jpg',
    imageMobile: OfertasAntecipadasBlackMobile,
    link: 'https://amzn.to/3YWduSk',
    alt: 'Imagem laranja escrito esquenta de ofertas black friday, com uma mulher branca de cabelo castanho segurando uma caixa de produtos',
    label: 'ad:amazon:<<pre-black-friday>>:ofertas-antecipadas',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: PrimeOfertas,
    imageMobile: PrimeOfertasMobile,
    alt: 'Imagem azul cheia de caixas da amazon escrito esquenta de ofertas pré black friday',
    link: 'https://amzn.to/3YRErGs',
    label: 'ad:amazon:<<pre-black-friday>>:prime-ofertas',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: PrimeAssinaturaAnualOfertas,
    imageMobile: PrimeAssinaturaAnualOfertasMobile,
    alt: 'Imagem cheia de foto de filmes e séries com oferta de assinatura anual do prime video',
    link: 'https://amzn.to/3AwBfqt',
    label: 'ad:amazon:<<pre-black-friday>>:prime-assinatura-anual',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Livros50OffOfertas,
    imageMobile: Livros50OffOfertasMobile,
    alt: 'Imagem com fotos de livros famosos e escrito ofertas de livros 50% de desconto',
    link: 'https://amzn.to/40yJmgI',
    label: 'ad:amazon:<<pre-black-friday>>:livros-50-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Casa30OffOfertas,
    imageMobile: Casa30OffOfertasMobile,
    alt: 'Imagem com fotos de produtos de casa e escrito ofertas de items de casa e decoração 30% de desconto',
    link: 'https://amzn.to/3CgT9OE',
    label: 'ad:amazon:<<pre-black-friday>>:casa-30-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
  {
    imageDesktop: Brinquedos25OffOfertas,
    imageMobile: Brinquedos25OffOfertasMobile,
    alt: 'Imagem com fotos de brinquedos e escrito ofertas de brinquedos 25% de desconto',
    link: 'https://amzn.to/4fv6D7F',
    label: 'ad:amazon:<<pre-black-friday>>:brinquedos-25-off',
    page: `lotofacil:concurso:<<${numero}>>`
  },
]

export const adsPopupBanner = [
  // {
  //   imageDesktop: Banner99Pay130Cupom,
  //   imageMobile: Banner99Pay130Cupom,
  //   title: 'Ganhe até R$130 de Desconto na Corridas ao se cadastrar! Clique (Carro e Moto)',
  //   link: 'https://d.99app.com/prEUMSV?resource_id=38',
  //   alt: 'Imagem amarela escrita até 130 reais em cupom',
  //   discountCoupons: ['ATÉ R$130 CUPOM', 'CUPOM 5 REAIS'],
  //   startDate: '2024-11-30',
  //   endDate: '2024-12-14',
  // },
  // {
  //   imageDesktop: Banner99Pay200CDI,
  //   imageMobile: Banner99Pay200CDI,
  //   title: 'Seu Dinheiro Rendendo 200% do CDI!!! Clique e Cadastre-se',
  //   link: 'https://d.99app.com/prEUMSV?resource_id=38',
  //   alt: 'Imagem amarela escrita até 130 reais em cupom',
  //   discountCoupons: ['200% DO CDI', 'ATÉ R$5.000', 'CUPONS DIÁRIOS'],
  //   startDate: '2024-11-30',
  //   endDate: '2024-12-14',
  // },
  {
    imageDesktop: 'https://cf.shopee.com.br/file/br-11134258-7r98o-m3f7iyrl9ot7ac_xxhdpi',
    imageMobile: 'https://mms.img.susercontent.com/eaf7373972fca3d74763ec93ff803049@resize_ss700x700.jpg',
    link: 'https://s.shopee.com.br/7zvnYZd4ES',
    alt: 'Imagem laranja escrito 12 do 12 shopee promoções',
    discountCoupons: ['FRETE GRÁTIS', 'ATÉ 70% DE DESCONTO', 'CUPOM EXCLUSIVO 1° COMPRA'],
    title: 'Promoções da Shopee Até 70% Off Confira',
    startDate: '2024-12-01',
    endDate: '2024-12-14',
  },
  {
    imageDesktop: Oferta15OffNikeMeliuz,
    imageMobile: Oferta15OffNikeMeliuz,
    link: "https://www.meliuz.com.br/i/ref_55eb21ae?ref_source=81",
    alt: "Imagem rosa do aplicativo meliuz que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Centauro', '4% Off Consul', '5% Off Adidas', '8% Off Natura'],
    title: "Compre nas melhores lojas com até 30% Off! Cadastre-se",
    startDate: '2024-11-30',
  },
  {
    imageDesktop: "https://cuponomia-a.akamaihd.net/img/logo-fb.png",
    imageMobile: "https://cuponomia-a.akamaihd.net/img/logo-fb.png",
    link: "https://www.cuponomia.com.br/ref/f9f75b73d7a7?asid=e7d1b19b-f20d-493f-b6ba-c63ab53cfda1",
    alt: "Imagem roxa do aplicativo cuponomia que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Centauro', '4% Off Consul', '5% Off Adidas', '8% Off Natura'],
    title: "Suas Compras com Até 30% de Desconto!!! Cadastre-se",
    startDate: '2024-11-30',
  }
  // Adicione outros banners aqui
]

export const adsShopee = (numero: string | undefined) => [
  {
    "image": "https://down-br.img.susercontent.com/file/br-11134207-7r98q-lm4x14y0uii344.webp",
    "link": "https://s.shopee.com.br/7UzII8P71z",
    "title": "Fone de Ouvido Sem Fio Airpods PRO 3 com Cancelamento de Ruído Bluetooth Pop-Up para iOS e Android",
    "label": "ad:shopee:<<eletronicos>>:airpods-pro-3",
    "page": "produto:fone:airpods-pro-3",
    "oldPrice": 189.00,
    "newPrice": 68.90,
    "discountCoupons": [
      "64% off",
      "12x R$6,69 no cartão de crédito"
    ],
    "additionalInfo": [
      "Fone de ouvido Bluetooth com cancelamento de ruído",
      "Compatível com dispositivos iOS e Android",
      "Tecnologia Pop-Up para fácil conexão"
    ],
    "ratings": {
      "rating": 4.8,
      "reviews": 40300,
      "sold": 110500
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/83e72d752c263768d72a1e8bed73fc9e.webp",
    "link": "https://s.shopee.com.br/20eLklrrpB",
    "title": "Máquina De Cortar Cabelo Para Barba Masculina Designer Aleatório Elétrico Profissional",
    "label": "ad:shopee:<<beleza>>:maquina-cortar-cabelo-barba-masculina",
    "page": "produto:maquina:cortar-cabelo-barba-masculina",
    "oldPrice": 99.00,
    "newPrice": 19.00,
    "discountCoupons": [
      "47% off",
      "Frete grátis com cupom"
    ],
    "additionalInfo": [
      "Máquina elétrica para cortar cabelo e barba",
      "Design aleatório, modelo exclusivo",
      "Fácil de usar e ideal para uso profissional"
    ],
    "ratings": {
      "rating": 4.8,
      "reviews": 314200,
      "sold": 690200
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "deliveryTime": "Receba até 23/nov",
      "freeShipping": "Frete grátis"
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lpi1b64jptol8f.webp", // Exemplo de imagem do pisca-pisca
    link: "https://s.shopee.com.br/6pjK3qQq6s", // Link de exemplo para o pisca-pisca
    title: "Pisca Pisca 100 Leds USB Árvore Natal Decoração Colorido Led 10M Prova D'Agua Interno Bivolt",
    label: 'ad:shopee:<<festas>>:pisca-pisca-natal',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 24.99,
    newPrice: 19.99,
    discountCoupons: ["17% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis acima de R$19,00",
      "Envio nacional",
      "TAMANHOS: 10m 100 LEDs, 20m 200 LEDs, entre outros"
    ],
    ratings: {
      rating: 4.9,
      reviews: 2500,
      sold: 4700
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/sg-11134201-7rbl0-llm8940p3wkvaa.webp",
    "link": "https://s.shopee.com.br/6V6l7BB27J",
    "title": "Parafusadeira Furadeira 3/8 Pol. a Bateria 12V com Maleta e Acessórios LITH LT7035XS1",
    "label": "ad:shopee:<<ferramentas>>:parafusadeira-furadeira-lith-lt7035xs1",
    "page": "produto:ferramentas:parafusadeira-furadeira-lith-lt7035xs1",
    "oldPrice": 160.99,
    "newPrice": 149.90,
    "discountCoupons": [
      "7% off",
      "Até R$9,43 de desconto no frete com cupom"
    ],
    "additionalInfo": [
      "Parafusadeira e furadeira 3/8 Pol. a bateria 12V",
      "Acompanha maleta e acessórios para facilitar o uso",
      "Ideal para projetos de bricolagem e reparos domésticos"
    ],
    "ratings": {
      "rating": 4.9,
      "reviews": 2200,
      "sold": 3600
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "freeShippingDiscount": "Até R$9,43 de desconto no frete com cupom"
    },
    "bonusCoins": {
      "coins": 149,
      "description": "Compre e ganhe 149 Moeda(s) Shopee"
    },
    "paymentOptions": {
      "installments": "12x R$14,55 no cartão de crédito"
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/33fe76e1ea9527308dd3fcd924746f25.webp",
    "link": "https://s.shopee.com.br/7pcLGNjlfJ",
    "title": "Meias De Futebol Antiderrapantes Unissex Para Esportes Ao Ar Livre",
    "label": "ad:shopee:<<esportes>>:meias-futebol-antiderrapantes",
    "page": "produto:esportes:meias-futebol-antiderrapantes",
    "oldPrice": 22.50,
    "newPrice": 9.70,
    "discountCoupons": [
      "66% off",
      "Frete grátis com cupom comprando R$10,00"
    ],
    "additionalInfo": [
      "Meias antiderrapantes ideais para esportes como futebol",
      "Unissex, disponíveis em diferentes tamanhos e cores",
      "Perfeitas para atividades ao ar livre, garantindo conforto e estabilidade"
    ],
    "ratings": {
      "rating": 4.9,
      "reviews": 21700,
      "sold": 186600
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "freeShipping": "Frete grátis com cupom comprando R$10,00"
    },
    "productInfo": {
      "international": "Produto internacional objeto de declaração de importação e sujeito a impostos estaduais e federais"
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lzalk2aj974194.webp",
    "link": "https://s.shopee.com.br/50HxKZDB1E",
    "title": "Dermaroller barba Tônico Barba Robusta Acelera Crescimento Preenche e Fortalece Barba",
    "label": "ad:shopee:<<beleza>>:dermaroller-barba-tonico-barba-robusta",
    "page": "produto:beleza:dermaroller-barba-tonico-barba-robusta",
    "oldPrice": 122.90,
    "newPrice": 69.90,
    "discountCoupons": [
      "43% off",
      "Frete grátis com cupom"
    ],
    "additionalInfo": [
      "Dermaroller e tônico para barba",
      "Acelera o crescimento e fortalece os fios",
      "Ideal para preencher falhas e fortalecer a barba"
    ],
    "ratings": {
      "rating": 4.9,
      "reviews": 118,
      "sold": 239
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "deliveryTime": "Receba até 22/nov",
      "freeShipping": "Frete grátis com cupom"
    },
    "paymentOptions": {
      "installments": "12x R$6,78 no cartão de crédito"
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/sg-11134201-7rd74-lvplubjmaytn83.webp",
    "link": "https://s.shopee.com.br/tTvjg3Bz",
    "title": "Kit Pescaria Vara de Pesca Telescópica Vermelho 1.65m e Kit de Acessórios Conjunto Vara Molinete com Linha",
    "label": "ad:shopee:<<esportes>>:kit-pescaria-vara-telescopica-vermelha",
    "page": "produto:esportes:kit-pescaria-vara-telescopica-vermelha",
    "oldPrice": 91.41,
    "newPrice": 59.90,
    "discountCoupons": [
      "Frete grátis com cupom"
    ],
    "additionalInfo": [
      "Kit completo para pescaria com vara telescópica de 1.65m",
      "Inclui molinete, linha e acessórios para pesca",
      "Vara de pesca vermelha, ideal para iniciantes e profissionais"
    ],
    "ratings": {
      "rating": 4.6,
      "reviews": 72,
      "sold": 135
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "deliveryTime": "Receba até 22/nov",
      "freeShipping": "Frete grátis com cupom"
    },
    "paymentOptions": {
      "installments": "11x R$6,29 no cartão de crédito"
    }
  },
  {
    "image": "https://down-br.img.susercontent.com/file/sg-11134201-7rept-m2cjcrawhyuu34.webp",
    "link": "https://s.shopee.com.br/6fQBO224wQ",
    "title": "Kit Lavagem Automotiva Carro e Moto Shampoo Lava Autos Cera Tok Final Vonixx Pretinho 500ml Rejuvex e Acessórios Karbox",
    "label": "ad:shopee:<<automotivos>>:kit-lavagem-automotiva-carro-moto-vonixx",
    "page": "produto:automotivos:kit-lavagem-automotiva-carro-moto-vonixx",
    "oldPrice": 150.99,
    "newPrice": 106.99,
    "discountCoupons": [
      "Frete grátis com cupom"
    ],
    "additionalInfo": [
      "Kit completo para lavagem automotiva de carro e moto",
      "Inclui shampoo Lava Autos, cera Tok Final, Rejuvex e acessórios Karbox",
      "Produto ideal para dar brilho e proteção à pintura do seu veículo"
    ],
    "ratings": {
      "rating": 4.9,
      "reviews": 46,
      "sold": 70
    },
    "flashSale": {
      "startsAt": "termina em",
      "icon": "flash sale"
    },
    "shippingInfo": {
      "deliveryTime": "Receba até 23/nov",
      "freeShipping": "Frete grátis com cupom"
    },
    "paymentOptions": {
      "installments": "12x R$10,38 no cartão de crédito"
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/sg-11134201-7rd6s-lupk641ostlm7c.webp",
    link: "https://s.shopee.com.br/8znodlFfaH",
    title: "Mochila escolar feminina STITCH de grande capacidade para meninas e meninos/Bolsa de computador para estudante do ensino médio",
    label: 'ad:shopee:<<dia-a-dia>>:mochila-feminina-stitch',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 171.00,
    newPrice: 94.00,
    discountCoupons: ["67% off", "1% OFF", "R$3 de desconto", "2% OFF"]
  },
  {
    image: "https://down-br.img.susercontent.com/file/cn-11134207-7r98o-lv0c99q5bsul2b.webp",
    link: "https://s.shopee.com.br/1g1DuJG2Ms",
    title: "Kiko Lip Gloss 6.5ML /3D Hydra Lipgloss /Water Gloss Hidratante / Longa Duração Não Desbotante",
    label: 'ad:shopee:<<dia-a-dia>>:kiko-lip-gloss',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 69.60,
    newPrice: 22.33,
    discountCoupons: ["68% off", "R$3 de desconto", "R$5 de desconto"],
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lwyf4h6nymief6.webp",
    link: "https://s.shopee.com.br/1B4xJPUjgY",
    title: "Vestido Longo Feminino Midi com Fenda Lateral e detalhes luxo",
    label: 'ad:shopee:<<dia-a-dia>>:vestido-long-midi',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 68.00,
    newPrice: 60.99,
    discountCoupons: ["10% off", "5% de Cashback em Moedas"],
    additionalInfo: [
      "Frete grátis acima de R$19,00",
      "Envio nacional",
      "Parcelamento em até 12x de R$5,92"
    ],
    ratings: {
      rating: 4.8,
      reviews: 903,
      sold: 1500
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lpi1b64jptol8f.webp", // Exemplo de imagem do pisca-pisca
    link: "https://s.shopee.com.br/6pjK3qQq6s", // Link de exemplo para o pisca-pisca
    title: "Pisca Pisca 100 Leds USB Árvore Natal Decoração Colorido Led 10M Prova D'Agua Interno Bivolt",
    label: 'ad:shopee:<<festas>>:pisca-pisca-natal',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 24.99,
    newPrice: 19.99,
    discountCoupons: ["17% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis acima de R$19,00",
      "Envio nacional",
      "TAMANHOS: 10m 100 LEDs, 20m 200 LEDs, entre outros"
    ],
    ratings: {
      rating: 4.9,
      reviews: 2500,
      sold: 4700
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lymnuq0mfjpx9c.webp", // Substitua com a URL da imagem real do produto
    link: "https://s.shopee.com.br/9KQf2SKlfz", // Link de exemplo para o produto
    title: "Kit Jogo de Cozinha Silicone, Utensílios e Copo Suporte para Cozinheiro Cabo de Madeira MasterChef",
    label: 'ad:shopee:<<cozinha>>:kit-jogo-cozinha-masterchef',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 59.90,
    newPrice: 19.80,
    discountCoupons: ["67% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis com cupom",
      "Chega amanhã comprando até às 23h59",
      "COR: 12-Peça-Aleatório, 5-Peça-Aleatório-Sem-Copo, 12-Peça-Preto/Chumbo/Cinza, 12-Peça-Rosa/Vermelho/Lilás, 12-Peça-Verd-Claro/Verd-Escuro, 5-Peça-Vermelho-Sem-Copo"
    ],
    ratings: {
      rating: 4.8,
      reviews: 3200,
      sold: 5900
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-ls32k159tmwr89.webp",
    link: "https://s.shopee.com.br/10lX8NqbBo",
    title: "Penteadeira 50x30 Escrivaninha Suspensa Organizadora Maquiagens Quarto 100% MDF",
    label: 'ad:shopee:<<mobiliario>>:penteadeira-mdf',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 99.90,
    newPrice: 96.90,
    discountCoupons: ["3% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Frete grátis com cupom",
      "Chega amanhã comprando até às 23h59",
      "Material: 100% MDF de alta qualidade"
    ],
    ratings: {
      rating: 4.8,
      reviews: 821,
      sold: 1600
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lu969c1rem3k06.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/60AD5gzbTH", // Substitua com o link real
    title: "Kit Giovanna Baby Body Splash Classic Loção Hidratante Classic Sabonete Classic Desodorante Roll-On",
    label: 'ad:shopee:<<beleza>>:kit-giovanna-baby-classic',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 145.89,
    newPrice: 129.53,
    discountCoupons: ["34% off", "Parcelamento em até 10x de R$5,85 no cartão"],
    additionalInfo: [
      "Chega amanhã comprando até às 23h59",
      "Opções de parcelamento disponíveis"
    ],
    ratings: {
      rating: 4.9,
      reviews: 712,
      sold: 1400
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7qukw-lk8izpbl4to134.webp",
    link: "https://s.shopee.com.br/3fmuKY0WSR", // Substitua com o link real
    title: "Porta Tempero Condimento Inox 20 Potes Redondo + Suporte Giratório Com Etiquetas",
    label: 'ad:shopee:<<cozinha>>:porta-temperos-inox',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 139.90,
    newPrice: 87.90,
    discountCoupons: [
      "Frete grátis",
      "Com Etiqueta"
    ],
    ratings: {
      rating: 4.9,
      reviews: 2100,
      sold: 3600
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lx9s12wqayduf1.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/6fPttAzGRW", // Substitua com o link real
    title: "Jogo Xícaras de Chá Café e Pires Coração Madeira Mesa Posta Cantinho do Café Dolce Gusto Nespresso",
    label: 'ad:shopee:<<cozinha>>:jogo-xicaras-coracao-madeira',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 59.90,
    newPrice: 47.50,
    discountCoupons: ["21% off", "Combo disponível"],
    additionalInfo: [
      "Ideal para café, chá e para usar com máquinas Dolce Gusto ou Nespresso",
      "Material: Madeira e cerâmica",
      "Opções de combinação com pires e xícaras"
    ],
    ratings: {
      rating: 5.0,
      reviews: 328,
      sold: 621
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/0717fe00f09a2ef32de0e47501845889.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/9pMvfgR607", // Substitua com o link real
    title: "Escova Secadora Alisador Elétrica Quente Cabelo Com 3 Em1 Hair Styler",
    label: 'ad:shopee:<<beleza>>:escova-secadora-hair-styler',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 164.99,
    newPrice: 44.99,
    discountCoupons: ["Desconto de até 73%"],
    additionalInfo: [
      "Escova 3 em 1: secadora, alisadora e modeladora",
      "Ideal para diferentes tipos de cabelo",
      "Design moderno e prático para uso diário"
    ],
    ratings: {
      rating: 4.5,
      reviews: 60500,
      sold: 112300
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lvl1humlnbgp91.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/7AMAUv7ct2", // Substitua com o link real
    title: "Kit 12 Pares de Meias Soquete Unissex Cano Curto",
    label: 'ad:shopee:<<moda>>:kit-meias-soquete-unissex',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 69.90,
    newPrice: 25.97,
    discountCoupons: ["84% off", "5% OFF com cupons de loja"],
    additionalInfo: [
      "Kit com 12 pares de meias soquete, cano curto",
      "Disponível em várias cores e tamanhos",
      "Confortáveis e ideais para o uso diário"
    ],
    ratings: {
      rating: 4.9,
      reviews: 18300,
      sold: 37000
    },
    flashSale: {
      startsAt: "22:00, 7 de nov",
      icon: "flash sale"
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lom21qr0noef1b.webp", // Substitua com a URL real da imagem
    link: "https://s.shopee.com.br/8znogQSLoK", // Substitua com o link real
    title: "Conjunto Degradê Academia Fitness Legging Cintura Alta e Bojo Conjunto Feminino Oferta",
    label: 'ad:shopee:<<moda>>:conjunto-degrade-academia',
    page: `lotofacil:concurso:<<${numero}>>`,
    oldPrice: 85.90,
    newPrice: 56.90,
    discountCoupons: ["34% off", "Leve 2, Pague Menos"],
    additionalInfo: [
      "Conjunto feminino com legging de cintura alta e bojo",
      "Design degradê e material confortável para prática de atividades físicas",
      "Disponível em várias cores e tamanhos"
    ],
    ratings: {
      rating: 4.7,
      reviews: 933,
      sold: 2100
    }
  },
  {
    image: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lycvl57swt9h71.webp",
    link: "https://s.shopee.com.br/8fBaH2HLFS",
    title: "Rabo de Cavalo Cacheado Orgânico Extensão Aplique Volumoso",
    label: "ad:shopee:<<cabelos>>:rabo-de-cavalo-cacheado-organico",
    page: "lotofacil:concurso:<<${numero}>>",
    oldPrice: 68.90,
    newPrice: 59.00,
    discountCoupons: ["5% off", "Frete grátis com cupom"],
    additionalInfo: [
      "Rabo de cavalo cacheado orgânico com aplique volumoso",
      "Ideal para quem deseja mais volume e estilo",
      "Disponível em diferentes tamanhos e cores"
    ],
    ratings: {
      rating: 4.7,
      reviews: 1300,
      sold: 2400
    },
    shipping: {
      deliveryDate: "Receba até 6/dez",
      freeShipping: true,
      payment: {
        installments: "11x de R$5,99"
      }
    }
  }
];

export const hotmartAds = (numero: string | undefined) => [
  {
    image: "https://static-media.hotmart.com/Z_F-K2JXxfce4kIZkmQF43WgWOA=/filters:background_color(white)/hotmart/product_pictures/6f187266-f7bc-455c-86b2-001d5c3fbb09/cursohotmart.png",
    link: "https://go.hotmart.com/L70630408K",
    title: "Curso de Manicure e Pedicure Iniciante",
    label: 'ad:hotmart:<<curso>>:manicure-pedicure-iniciante-fabi-cardoso',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Manicure e Pedicure",
    oldPrice: 124.41,
    newPrice: 99.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/v1OcxFzAAMVYaQVy6yl4jWWQjIU=/filters:background_color(white)/hotmart/product_pictures/ae53f878-7ac7-472b-b9cf-982ee0ca718a/CpiadeOpenHouse4.png",
    link: "https://go.hotmart.com/S96481313C",
    title: "Curso de Unhas (Fibra + Esmaltação em Gel + Manicure e Pedicure)",
    label: 'ad:hotmart:<<curso>>:curso-unhas-fibra-esmaltacao-hellen-barbosa',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Unhas (Fibra + Esmaltação em Gel + Manicure e Pedicure)",
    oldPrice: 73.85,
    newPrice: 67.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/Xe2qw-u9rj49x0V9a7r8mffULHA=/filters:background_color(white)/hotmart/product_pictures/6a8864b1-25ab-40c9-a537-35457471ca27/Hotmart001.png",
    link: "https://go.hotmart.com/R71606935E",
    title: "Curso Depilação Profissional - Oficial",
    label: 'ad:hotmart:<<curso>>:depilacao-profissional-priscilla-fidelis',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso Depilação Profissional - Oficial",
    oldPrice: 116.16,
    newPrice: 97.00,
    discountCoupons: []
  },
  {
    image: "https://static-media.hotmart.com/2cSARpacSYlk5w6VL16eMxBwWfc=/filters:background_color(white)/hotmart/product_pictures/e59007c8-851e-47b9-aef3-dbb4ab686bbc/Lash_LiftingCapa1.png",
    link: "https://go.hotmart.com/J96481610G",
    title: "Curso de Lash Lifting + Extensão de Cílios + Bônus",
    label: 'ad:hotmart:<<curso>>:lash-lifting-pauline-mocelin',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Curso de Lash Lifting + Extensão de Cílios + Bônus",
    oldPrice: 397.00,
    newPrice: 197.00,
    discountCoupons: []
  }
]

export const appOfertasAds = (numero: string | undefined) => [
  {
    image: Oferta15OffNikeMeliuz,
    link: "https://www.meliuz.com.br/i/ref_55eb21ae?ref_source=81",
    title: "Meliuz: Até 30% Off nas compras",
    label: 'ad:apppromo:<<app-ofertas>>:meliuz',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Imagem rosa do aplicativo meliuz que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Centauro', '4% Off Consul']
  },
  {
    image: "https://cuponomia-a.akamaihd.net/img/logo-fb.png",
    link: "https://www.cuponomia.com.br/ref/f9f75b73d7a7?asid=e7d1b19b-f20d-493f-b6ba-c63ab53cfda1",
    title: "Cuponomia: Até 30% Off nas compras",
    label: 'ad:apppromo:<<app-ofertas>>:cuponomia',
    page: `lotofacil:concurso:<<${numero}>>`,
    alt: "Imagem roxa do aplicativo cuponomia que tem ofertas e cupons de desconto",
    discountCoupons: ['5% Off Adidas', '8% Off Natura']
  }
]

