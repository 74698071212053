import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/layout';
import Breadcrumb from '../../components/breadcrumb';
import { getAllLotteryContest } from '../../utils/contest';
import { generateFakeArray } from '../../utils/functions';
import styles from '../../styles/Home.module.css';
import { LoteriaType } from '../../utils/interfaces';

// Importando as informações das loterias
import { loterries } from '../../utils/lotteries';

const ConstestIcon = lazy(() => import('../../components/ConstestIcon'));
const LotofacilResume = React.lazy(() => import('../../components/Seo/lotofacil'));
const MegaSenaResume = React.lazy(() => import('../../components/Seo/megasena'));
const QuinaResume = React.lazy(() => import('../../components/Seo/quina'));
const LotomaniaResume = React.lazy(() => import('../../components/Seo/lotomania'));
const SuperSeteResume = React.lazy(() => import('../../components/Seo/supersete'));
const DiaDeSorteResume = React.lazy(() => import('../../components/Seo/diadesorte'));
const DuplaSenaResume = React.lazy(() => import('../../components/Seo/duplasena'));

const LotteryHome: React.FC = () => {
  const [contests, setContests] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Usando o hook useParams para acessar o lotteryType da URL
  const { lotteryType } = useParams<{ lotteryType: LoteriaType | undefined }>();

  const fetchContests = useCallback(async () => {
    const fetchedContests = await getAllLotteryContest(lotteryType);
    setContests(fetchedContests);
    setLoading(false);
  }, [lotteryType]);

  useEffect(() => {
    fetchContests();
  }, [fetchContests]);

  const fakeArray: number[] = generateFakeArray(6);

  // Recuperando os dados da loteria com base no lotteryType
  const lotteryInfo = lotteryType && loterries[lotteryType] ? loterries[lotteryType] : {
    title: "Loteria não encontrada",
    description: "Informações não disponíveis.",
    keywords: "loteria não encontrada",
    paths: [{ href: "/", label: "Home" }],
    loteriaName: "Loteria desconhecida",
    contestUrl: "/",
    loteriaColor: "#000000"
  };

  return (
    <Layout title={lotteryInfo.title} description={lotteryInfo.description} keywords={lotteryInfo.keywords}>
      <div style={{ padding: '1rem' }}>
        <Breadcrumb paths={lotteryInfo.paths} />
        <h1 style={{ marginTop: '0.5rem' }} className="subtitle">
          Resultado de Todos os Concursos da {lotteryInfo.loteriaName}:
        </h1>
        <p>
          Informações de todos os sorteios/concursos da {lotteryInfo.loteriaName} de hoje, ontem e do mês inteiro (Segunda, Terça, Quarta, Quinta, Sexta e Sábado)
        </p>

        <section className={styles.contestsContainer}>
          <h2>Últimos resultados da {lotteryInfo.loteriaName}</h2>
          <div>
            {loading ? fakeArray.map((_, index) => (
              <div className={styles.contestsResultLink} key={index}>
                <Suspense fallback='Imagem Carregando...'>
                  <ConstestIcon />
                </Suspense>
                <p>
                  <span>Resultado {lotteryInfo.loteriaName} Concurso ...</span>
                  <span>Confira os Números Sorteados e Ganhadores</span>
                </p>
              </div>
            )) :
              contests?.map((contest: string) => (
                <a href={`${lotteryInfo.contestUrl}${contest}`} className={styles.contestsResultLink}
                  style={{
                    borderColor: lotteryInfo.loteriaColor,
                    textDecorationColor: lotteryInfo.loteriaColor,
                    color: lotteryInfo.loteriaColor
                  }} 
                  key={`${lotteryInfo.contestUrl}-${contest}`}>
                  <Suspense fallback='Imagem Carregando...'>
                    <ConstestIcon color={lotteryInfo.loteriaColor}/>
                  </Suspense>
                  <p>
                    <strong>Resultado {lotteryInfo.loteriaName} Concurso {contest}</strong>
                    <span>Confira os Números Sorteados e Ganhadores</span>
                  </p>
                </a>
              ))}
          </div>
        </section>
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          {lotteryType === 'lotofacil' && (<LotofacilResume />)}
          {lotteryType === 'megasena' && (<MegaSenaResume />)}
          {lotteryType === 'quina' && (<QuinaResume />)}
          {lotteryType === 'lotomania' && (<LotomaniaResume />)}
          {lotteryType === 'diadesorte' && (<DiaDeSorteResume />)}
          {lotteryType === 'duplasena' && (<DuplaSenaResume />)}
          {lotteryType === 'supersete' && (<SuperSeteResume />)}
        </Suspense>
      </div>
    </Layout>
  );
}

export default LotteryHome;
