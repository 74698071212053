export const generateFakeArray = (num: number) => {
    return Array.from(Array(num).keys()).map(index => index + 1);
}

export const sum = (num: number | string | undefined, quantity: number) => {
    if(!num) return 0

    return Number(num) + quantity
}

export const sub = (num: number | string | undefined, quantity: number) => {
    if(!num) return 0

    return Number(num) - quantity
}

export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (_, index) => start + index * step
    )

export function cleanUrlParam(url: string): string {
    return url.replace(/[^0-9]/g, '');
}