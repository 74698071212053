import React from 'react';
import Layout from '../../components/layout/';
import Breadcrumb from '../../components/breadcrumb';
import styles from '../../styles/Transparency.module.css';  // Importando o CSS Module

const Transparency = () => {
  return (
    <Layout 
      title="Transparência - Minha Sorte" 
      description="Saiba como o site Minha Sorte opera com total transparência. Veja informações detalhadas sobre nossas práticas, políticas e compromissos." 
      keywords="transparência, práticas, políticas, compromissos, Minha Sorte"
    >
      <div className={styles.transparenciaContainer}>
        <Breadcrumb paths={[
          { href: '/', label: 'Home' },
          { href: '/transparencia', label: 'Transparência' },
        ]} />

        <h1 className={styles.transparenciaTitle}>Transparência</h1>
        
        <div className={styles.transparenciaText}>
          <p>No <strong>Minha Sorte</strong>, valorizamos a transparência em todas as nossas operações. Abaixo, você encontrará informações detalhadas sobre nossas práticas, políticas e compromissos.</p>

          <h2>1. Política de Privacidade</h2>
          <p>Coletamos apenas dados de uso (páginas visitadas, dispositivos) para análise e melhoria do site. Não coletamos informações pessoais (nome, e-mail). Para mais detalhes, leia nossa <a href="/politica-de-privacidade" className={styles.transparenciaLink}>Política de Privacidade</a>.</p>

          <h2>2. Aviso Legal</h2>
          <p>Nosso aviso legal descreve as condições e responsabilidades ao utilizar o site Minha Sorte. Inclui informações sobre o uso de cookies e anúncios de terceiros. Confira nosso <a href="/aviso-legal" className={styles.transparenciaLink}>Aviso Legal</a> para mais detalhes.</p>

          <h2>3. Anúncios e Monetização</h2>
          <p>Utilizamos diversas redes de anúncios, incluindo Google AdSense, para monetizar o site. Esses anúncios podem usar cookies para exibir anúncios personalizados. Para mais informações sobre os anúncios, veja nossa <a href="/aviso-legal" className={styles.transparenciaLink}>página de Aviso Legal</a>.</p>

          <h2>4. Compromisso com a Qualidade</h2>
          <p>Nos esforçamos para fornecer informações precisas e úteis sobre sorteios e loterias. No entanto, não garantimos que todos os dados sejam completos ou isentos de erros. Utilizamos diversas fontes para garantir a precisão das informações fornecidas.</p>

          <h2>5. Contato</h2>
          <p>Para qualquer dúvida ou esclarecimento, entre em contato conosco pelo e-mail: <a href="mailto:admin@minhasorte.com.br" className={styles.transparenciaLink}>admin@minhasorte.com.br</a>.</p>
        </div>
      </div>
    </Layout>
  );
};

export default Transparency;
