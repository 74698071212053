import React, { useEffect, useState } from 'react';
import styles from '../../styles/PrivacyControl.module.css';

const PrivacyControl = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem('privacyAccepted') === 'true';
    setIsAccepted(accepted);
  }, []);

  const handleAccept = () => {
    setIsAccepted(true);
    localStorage.setItem('privacyAccepted', 'true');
  };

  if (isAccepted) return null;

  return (
    <div className={styles.privacyControl}>
      <div className={styles.privacyBanner}>
        <p>Usamos cookies para melhorar sua experiência. Ao continuar navegando, você aceita nossa <a href="/politica-de-privacidade">Política de Privacidade</a>.</p>
        <div>
          <button onClick={handleAccept} className={styles.btnAccept}>Aceitar</button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyControl;
