import { LoteriaType, Lotteries, LotteryContestData } from "./interfaces";

export const loterries: Lotteries = {
    "lotofacil": {
      "title": "Últimos Resultados Concursos da Lotofácil",
      "description": "Confira os resultados de todos os concursos da Lotofácil. Informações atualizadas sobre os sorteios de hoje, ontem e do mês inteiro, incluindo Segunda, Terça, Quarta, Quinta, Sexta e Sábado.",
      "keywords": "resultados lotofácil, concursos lotofácil, sorteios lotofácil, lotofácil hoje, resultados diários lotofácil",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/lotofacil", "label": "Lotofácil" }
      ],
      "loteriaName": "Lotofácil",
      "contestUrl": "/lotofacil/concurso/",
      "loteriaColor": "var(--lotofacil-color)"
    },
    "megasena": {
      "title": "Últimos Resultados Concursos da Mega-Sena",
      "description": "Veja os resultados da Mega-Sena, incluindo os concursos de hoje e das semanas anteriores.",
      "keywords": "resultados mega-sena, concursos mega-sena, sorteios mega-sena, mega-sena hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/megasena", "label": "Mega-Sena" }
      ],
      "loteriaName": "Mega-Sena",
      "contestUrl": "/megasena/concurso/",
      "loteriaColor": "var(--megasena-color)"
    },
    "quina": {
      "title": "Últimos Resultados Concursos da Quina",
      "description": "Acompanhe os resultados de todos os concursos da Quina. Informações atualizadas sobre os sorteios de hoje e as semanas anteriores.",
      "keywords": "resultados quina, concursos quina, sorteios quina, quina hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/quina", "label": "Quina" }
      ],
      "loteriaName": "Quina",
      "contestUrl": "/quina/concurso/",
      "loteriaColor": "var(--quina-color)"
    },
    "supersete": {
      "title": "Últimos Resultados Concursos do Super Sete",
      "description": "Confira os resultados do Super Sete, incluindo os concursos de hoje e de semanas anteriores.",
      "keywords": "resultados super sete, concursos super sete, sorteios super sete, super sete hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/supersete", "label": "Super Sete" }
      ],
      "loteriaName": "Super Sete",
      "contestUrl": "/supersete/concurso/",
      "loteriaColor": "var(--supersete-color)"
    },
    "diadesorte": {
      "title": "Últimos Resultados Concursos do Dia de Sorte",
      "description": "Acompanhe os resultados do Dia de Sorte, com informações dos sorteios de hoje, ontem e do mês inteiro.",
      "keywords": "resultados dia de sorte, concursos dia de sorte, sorteios dia de sorte, dia de sorte hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/diadesorte", "label": "Dia de Sorte" }
      ],
      "loteriaName": "Dia de Sorte",
      "contestUrl": "/diadesorte/concurso/",
      "loteriaColor": "var(--diadesorte-color)"
    },
    "lotomania": {
      "title": "Últimos Resultados Concursos da Lotomania",
      "description": "Veja os resultados da Lotomania, com informações atualizadas sobre os sorteios de hoje e das semanas anteriores.",
      "keywords": "resultados lotomania, concursos lotomania, sorteios lotomania, lotomania hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/lotomania", "label": "Lotomania" }
      ],
      "loteriaName": "Lotomania",
      "contestUrl": "/lotomania/concurso/",
      "loteriaColor": "var(--lotomania-color)"
    },
    "duplasena": {
      "title": "Últimos Resultados Concursos da Dupla Sena",
      "description": "Acompanhe os resultados da Dupla Sena, com os sorteios mais recentes e informações detalhadas sobre cada concurso.",
      "keywords": "resultados dupla sena, concursos dupla sena, sorteios dupla sena, dupla sena hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/duplasena", "label": "Dupla Sena" }
      ],
      "loteriaName": "Dupla Sena",
      "contestUrl": "/duplasena/concurso/",
      "loteriaColor": "var(--duplasena-color)"
    },
    "loteca": {
      "title": "Últimos Resultados Concursos da Loteca",
      "description": "Confira os resultados de todos os concursos da Loteca. Informações sobre os sorteios de hoje e os jogos anteriores.",
      "keywords": "resultados loteca, concursos loteca, sorteios loteca, loteca hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/loteca", "label": "Loteca" }
      ],
      "loteriaName": "Loteca",
      "contestUrl": "/loteca/concurso/",
      "loteriaColor": "var(--loteca-color)"
    },
    "maismilionaria": {
      "title": "Últimos Resultados Concursos da Mais Milionária",
      "description": "Acompanhe os resultados da Mais Milionária, com sorteios de prêmios incríveis e concursos atualizados.",
      "keywords": "resultados mais milionária, concursos mais milionária, sorteios mais milionária, mais milionária hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/maismilionaria", "label": "Mais Milionária" }
      ],
      "loteriaName": "Mais Milionária",
      "contestUrl": "/maismilionaria/concurso/",
      "loteriaColor": "var(--maismilionaria-color)"
    },
    "timemania": {
      "title": "Últimos Resultados Concursos da Timemania",
      "description": "Confira os resultados da Timemania, com todos os sorteios de hoje e de outros concursos passados.",
      "keywords": "resultados timemania, concursos timemania, sorteios timemania, timemania hoje",
      "paths": [
        { "href": "/", "label": "Home" },
        { "href": "/timemania", "label": "Timemania" }
      ],
      "loteriaName": "Timemania",
      "contestUrl": "/timemania/concurso/",
      "loteriaColor": "var(--timemania-color)"
    }
}

export const generateLotteryContestData = (
  lotteryType: LoteriaType | undefined, // Agora inclui 'quina', 'supersete', 'diadesorte', 'lotomania', 'duplasena'
  number: string, 
  contestWeekDay: string, 
  contestFormatDate: string
): LotteryContestData => {
  if (lotteryType === 'lotofacil') {
    return {
      lotofacil: {
        title: `Resultado da Lotofácil ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Lotofácil ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Lotofácil também disponíveis. Acesse agora para mais informações!`,
        keywords: `lotofácil, resultado lotofácil, lotofácil concurso ${number}, resultado concurso lotofácil ${number}, números sorteados lotofácil, lotofácil de hoje, prêmio lotofácil, ganhadores lotofácil, sorteio lotofácil, loteria caixa, resultados loterias caixa, apostas lotofácil, estatísticas lotofácil, lotofácil online, bolão lotofácil, dicas lotofácil, estratégia lotofácil, como jogar na lotofácil, conferir resultado lotofácil, mega sena, quina, loto fácil, loteria federal, sorteios lotofácil, acumulado lotofácil, lotofácil acumulada, lotofácil estatísticas, lotofácil premiação, lotofácil últimos resultados, ganhadores concurso lotofácil, números sorteados lotofácil, resultados da lotofácil, como ganhar na lotofácil`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/lotofacil', label: 'Lotofácil' },
          { href: `/lotofacil/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Lotofácil",
        startPrize: "1,7 milhão",
        quantityOfDrawNumbers: 15,
        quantityOfPrizes: 4,
        listOfPrizes: "11, 12, 13, ou 15 números",
        listOfDrawDays: "segunda-feira, terça-feira, quarta-feira, quinta-feira, sexta-feira e sábado"
      }
    };
  }

  if (lotteryType === 'megasena') {
    return {
      megasena: {
        title: `Resultado da Mega-Sena ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Mega-Sena ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Mega-Sena também disponíveis. Acesse agora para mais informações!`,
        keywords: `mega-sena, resultado mega-sena, mega-sena concurso ${number}, resultado concurso mega-sena ${number}, números sorteados mega-sena, mega-sena de hoje, prêmio mega-sena, ganhadores mega-sena, sorteio mega-sena, loteria caixa, resultados loterias caixa, apostas mega-sena, estatísticas mega-sena, mega-sena online, bolão mega-sena, dicas mega-sena, estratégia mega-sena, como jogar na mega-sena, conferir resultado mega-sena, lotofácil, quina, loto fácil, loteria federal, sorteios mega-sena, acumulado mega-sena, mega-sena acumulada, mega-sena estatísticas, mega-sena premiação, mega-sena últimos resultados, ganhadores concurso mega-sena, números sorteados mega-sena, resultados da mega-sena, como ganhar na mega-sena`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/megasena', label: 'Mega-Sena' },
          { href: `/megasena/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Mega-Sena",
        startPrize: "R$ 3 milhões",
        quantityOfDrawNumbers: 6,
        quantityOfPrizes: 3,
        listOfPrizes: "4, 5 ou 6 números",
        listOfDrawDays: "terça-feira, quinta-feira e sábado"
      }
    };
  }

  // Se for Quina
  if (lotteryType === 'quina') {
    return {
      quina: {
        title: `Resultado da Quina ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Quina ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Quina também disponíveis. Acesse agora para mais informações!`,
        keywords: `quina, resultado quina, quina concurso ${number}, resultado concurso quina ${number}, números sorteados quina, quina de hoje, prêmio quina, ganhadores quina, sorteio quina, loteria caixa, resultados loterias caixa, apostas quina, estatísticas quina, quina online, bolão quina, dicas quina, estratégia quina, como jogar na quina, conferir resultado quina, mega sena, lotofácil, loto fácil, loteria federal, sorteios quina, acumulado quina, quina acumulada, quina estatísticas, quina premiação, quina últimos resultados, ganhadores concurso quina, números sorteados quina, resultados da quina, como ganhar na quina`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/quina', label: 'Quina' },
          { href: `/quina/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Quina",
        startPrize: "R$ 1 milhão",
        quantityOfDrawNumbers: 5,
        quantityOfPrizes: 4,
        listOfPrizes: "2, 3, 4 ou 5 números",
        listOfDrawDays: "segunda-feira, terça-feira, quarta-feira, quinta-feira, sexta-feira e sábado"
      }
    };
  }

  // Se for Supersete
  if (lotteryType === 'supersete') {
    return {
      supersete: {
        title: `Resultado da SuperSete ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso SuperSete ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da SuperSete também disponíveis. Acesse agora para mais informações!`,
        keywords: `supersete, resultado supersete, supersete concurso ${number}, resultado concurso supersete ${number}, números sorteados supersete, supersete de hoje, prêmio supersete, ganhadores supersete, sorteio supersete, loteria caixa, resultados loterias caixa, apostas supersete, estatísticas supersete, supersete online, bolão supersete, dicas supersete, estratégia supersete, como jogar na supersete, conferir resultado supersete, mega sena, lotofácil, quina, loto fácil, loteria federal, sorteios supersete, acumulado supersete, supersete acumulada, supersete estatísticas, supersete premiação, supersete últimos resultados, ganhadores concurso supersete, números sorteados supersete, resultados da supersete, como ganhar na supersete`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/supersete', label: 'SuperSete' },
          { href: `/supersete/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "SuperSete",
        startPrize: "R$ 300 mil",
        quantityOfDrawNumbers: 7,
        quantityOfPrizes: 5,
        listOfPrizes: "3, 4, 5, 6 ou 7 números",
        listOfDrawDays: "segunda-feira, quarta-feira e sexta-feira"
      }
    };
  }

  // Se for Dia de Sorte
  if (lotteryType === 'diadesorte') {
    return {
      diadesorte: {
        title: `Resultado do Dia de Sorte ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Dia de Sorte ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores do Dia de Sorte também disponíveis. Acesse agora para mais informações!`,
        keywords: `diadesorte, resultado diadesorte, diadesorte concurso ${number}, resultado concurso diadesorte ${number}, números sorteados diadesorte, diadesorte de hoje, prêmio diadesorte, ganhadores diadesorte, sorteio diadesorte, loteria caixa, resultados loterias caixa, apostas diadesorte, estatísticas diadesorte, diadesorte online, bolão diadesorte, dicas diadesorte, estratégia diadesorte, como jogar no diadesorte, conferir resultado diadesorte, mega sena, lotofácil, quina, loto fácil, loteria federal, sorteios diadesorte, acumulado diadesorte, diadesorte acumulada, diadesorte estatísticas, diadesorte premiação, diadesorte últimos resultados, ganhadores concurso diadesorte, números sorteados diadesorte, resultados do diadesorte, como ganhar no diadesorte`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/diadesorte', label: 'Dia de Sorte' },
          { href: `/diadesorte/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Dia de Sorte",
        startPrize: "R$ 300 mil",
        quantityOfDrawNumbers: 7,
        quantityOfPrizes: 4,
        listOfPrizes: "4, 5, 6 ou 7 números",
        listOfDrawDays: "terça-feira, quinta-feira e sábado"
      }
    };
  }

  // Se for Lotomania
  if (lotteryType === 'lotomania') {
    return {
      lotomania: {
        title: `Resultado da Lotomania ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Lotomania ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Lotomania também disponíveis. Acesse agora para mais informações!`,
        keywords: `lotomania, resultado lotomania, lotomania concurso ${number}, resultado concurso lotomania ${number}, números sorteados lotomania, lotomania de hoje, prêmio lotomania, ganhadores lotomania, sorteio lotomania, loteria caixa, resultados loterias caixa, apostas lotomania, estatísticas lotomania, lotomania online, bolão lotomania, dicas lotomania, estratégia lotomania, como jogar na lotomania, conferir resultado lotomania, mega sena, lotofácil, quina, loto fácil, loteria federal, sorteios lotomania, acumulado lotomania, lotomania acumulada, lotomania estatísticas, lotomania premiação, lotomania últimos resultados, ganhadores concurso lotomania, números sorteados lotomania, resultados da lotomania, como ganhar na lotomania`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/lotomania', label: 'Lotomania' },
          { href: `/lotomania/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Lotomania",
        startPrize: "R$ 1 milhão",
        quantityOfDrawNumbers: 50,
        quantityOfPrizes: 4,
        listOfPrizes: "15, 16, 17 ou 20 números",
        listOfDrawDays: "segunda-feira, quarta-feira e sexta-feira"
      }
    };
  }

  // Se for Dupla Sena
  if (lotteryType === 'duplasena') {
    return {
      duplasena: {
        title: `Resultado da Dupla Sena ${number} - Confira Números Sorteados e Ganhadores`,
        description: `Confira o resultado do concurso Dupla Sena ${number} de hoje ${contestWeekDay}, ${contestFormatDate}. Veja os números sorteados, ganhadores, e detalhes da premiação. Resultados de ontem e anteriores da Dupla Sena também disponíveis. Acesse agora para mais informações!`,
        keywords: `duplasena, resultado duplasena, duplasena concurso ${number}, resultado concurso duplasena ${number}, números sorteados duplasena, duplasena de hoje, prêmio duplasena, ganhadores duplasena, sorteio duplasena, loteria caixa, resultados loterias caixa, apostas duplasena, estatísticas duplasena, duplasena online, bolão duplasena, dicas duplasena, estratégia duplasena, como jogar na duplasena, conferir resultado duplasena, mega sena, lotofácil, quina, loto fácil, loteria federal, sorteios duplasena, acumulado duplasena, duplasena acumulada, duplasena estatísticas, duplasena premiação, duplasena últimos resultados, ganhadores concurso duplasena, números sorteados duplasena, resultados da duplasena, como ganhar na duplasena`,
        paths: [
          { href: '/', label: 'Home' },
          { href: '/duplasena', label: 'Dupla Sena' },
          { href: `/duplasena/concurso/${number}`, label: `Concurso ${number}` }
        ],
        loteriaName: "Dupla Sena",
        startPrize: "R$ 1 milhão",
        quantityOfDrawNumbers: 6,
        quantityOfPrizes: 3,
        listOfPrizes: "4, 5 ou 6 números",
        listOfDrawDays: "terça-feira, quinta-feira e sábado"
      }
    };
  }

  return {}; // Default return if no lottery type is matched
};




